import React from "react"
import Layout from "../components/Layout"

import IntroBanner from "../components/Sections/Resources/IntroBanner"
import GrowthHandbook from "../components/Sections/Resources/GrowthHandbook"
import EmailCourses from "../components/Sections/Resources/EmailCourses"
import Webinar from "../components/Sections/Resources/Webinar"
import ReadyToLaunch from "../components/Sections/Resources/ReadyToLaunch"
import VideoResources from "../components/Sections/Resources/VideoResources"

const metadata = {
  title:
    "Ecommerce Referral Program Resources | Customer Referral Program Software for eCommerce",
  description:
    "Resources for every ecommerce referral program to boost your word of mouth success with ReferralCandy",
}

export default (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <IntroBanner />
      <Webinar />
      <VideoResources />
      <GrowthHandbook />
      <EmailCourses />
      <ReadyToLaunch />
    </Layout>
  )
}
