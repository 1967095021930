import React, { useState } from "react"
import css from "./index.module.scss"
import Grid from "../../../Grid"
import Button from "../../../Button"
import * as modal from "../../../Modal"

import EmailCaptureModal, { SEGMENT_KEYS } from "../EmailCaptureModal"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query {
    banner: file(
      relativePath: {
        eq: "images/how_it_works/how-referrals-work-Referralcandy-Referral-program-software-sets-up-in-10-minutes-referralcandy-features-how-it-works.png"
      }
    ) {
      childImageSharp {
        fixed(height: 350) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const IntroBanner = () => {
  const { banner } = useStaticQuery(query)
  const [checklistModal, setChecklistModal] = useState(false)

  const toggleModal = (action) => {
    setChecklistModal(modal.update(checklistModal, action))
  }

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <h2>Referral Program Checklist</h2>
        <p>
          A handy and printable checklist to help you get your first referral
          campaign up and running ASAP
        </p>
        <Button el="button" {...modal.bindToTrigger(toggleModal)}>
          Get the checklist
        </Button>
      </div>
      <div className={css.image}>
        <Img
          fixed={banner.childImageSharp.fixed}
          alt="Referralcandy referral marketing program software - referral marketing platform refer a friend software"
        />
      </div>
      <EmailCaptureModal
        show={checklistModal}
        onUpdate={toggleModal}
        segmentDataKey={SEGMENT_KEYS.REFERRAL_MARKETING_CHECKLIST}
      />
    </Grid>
  )
}

export default IntroBanner
