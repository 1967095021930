import React from "react"
import Grid from "../../../Grid"
import Button from "../../../Button"
import SignupLink from "../../../SignupLink"

import css from "./index.module.scss"

export default () => {
  return (
    <Grid className={css.ctaContainer} centeredClassName={css.centered}>
      <div className={css.text}>
        <h2>
          Ready to launch
          <br />
          your own referral program?
        </h2>
        <SignupLink el={Button} purpose="primary">
          Try It For Free
        </SignupLink>
      </div>
    </Grid>
  )
}
