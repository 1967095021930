import React from "react"
import Modal from "../../../Modal"
import css from "./index.module.scss"
import Button from "../../../Button"
import { Location } from "@reach/router"

export const SEGMENT_KEYS = {
  REFERRAL_MARKETING_CHECKLIST: "Referral Marketing Checklist",
  GROWTH_HANDBOOK_1: "Growth Handbook Chapter 1: Research",
  GROWTH_HANDBOOK_2: "Growth Handbook Chapter 2: Blogging",
  GROWTH_HANDBOOK_3: "Growth Handbook Chapter 3: Conversion",
  REFERRAL_PROGRAM_QUICKSTART: "Referral Program Quickstart Email course",
  WOM_EMAIL_COURSE: "Word Of Mouth Email course",
}

// Mapping segmentDataKey to Segment Constants used to Kickstart Email Marketing
const keyToSegmentData = (key, url) => {
  const shared = {
    referral_url: url,
    source: "marketing website",
    collection_point: "popup",
  }
  let specific
  switch (key) {
    case SEGMENT_KEYS.REFERRAL_MARKETING_CHECKLIST:
      specific = {
        delivery_method: "email",
        content_format: "pdf",
        content_title: "Referral Marketing Checklist",
        content_type: "Ebook",
      }
      break
    case SEGMENT_KEYS.GROWTH_HANDBOOK_1:
      specific = {
        delivery_method: "email",
        content_format: "pdf",
        content_title: "Grow Your Store - Chapter 1",
        content_type: "Ebook",
      }
      break
    case SEGMENT_KEYS.GROWTH_HANDBOOK_2:
      specific = {
        delivery_method: "email",
        content_format: "pdf",
        content_title: "Grow Your Store - Chapter 2",
        content_type: "Ebook",
      }
      break
    case SEGMENT_KEYS.GROWTH_HANDBOOK_3:
      specific = {
        delivery_method: "email",
        content_format: "pdf",
        content_title: "Grow Your Store - Chapter 3",
        content_type: "Ebook",
      }
      break
    case SEGMENT_KEYS.REFERRAL_PROGRAM_QUICKSTART:
      specific = {
        delivery_method: "email series",
        content_format: "email course",
        content_title: "Referral Program Examples",
        content_type: "Email Course",
      }
      break
    case SEGMENT_KEYS.WOM_EMAIL_COURSE:
      specific = {
        delivery_method: "email series",
        content_format: "email course",
        content_title: "Double Your WOM",
        content_type: "Email Course",
      }
      break
    default:
      break
  }

  return Object.assign(shared, specific)
}

const EmailCaptureModal = ({ segmentDataKey, show, onUpdate, currentUrl }) => {
  const status = "initial"
  const data = keyToSegmentData(segmentDataKey, currentUrl)
  switch (status) {
    case "success":
      return (
        <Modal show={show} onUpdate={onUpdate} header={<h2>Success!</h2>}>
          <p>
            <b>{segmentDataKey}</b> is on it’s way.
          </p>
          <Button
            el="button"
            onClick={() => {
              onUpdate({ type: "CLOSE_MODAL" })
            }}
          >
            Close
          </Button>
        </Modal>
      )
    case "initial":
    default:
      // We move from Segment to Vero directly to reduce cost
      // https://app.clubhouse.io/rc/story/50232/resources-page-update
      return (
        <Modal
          show={show}
          onUpdate={onUpdate}
          header={<h2>Enter your email to get instant access</h2>}
        >
          <form
            action="https://app.getvero.com/forms/ff748d1d5a78aad2e994626a69629296"
            method="post"
            className={css.input_container}
          >
            <label hidden>Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="your@emailaddress.com"
              required
            />
            <input
              type="hidden"
              name="event[content_title]"
              value={data.content_title}
            />
            <input
              name="redirect_on_success"
              type="hidden"
              value={`${currentUrl}#vero_success`}
            />
            <Button
              el="button"
              type="submit"
              value="Subscribe"
              shrinkwrap
              purpose="primary"
              className={css.button}
            >
              Give it to me!
            </Button>
          </form>
          We’ll send the {segmentDataKey} to your email straight away, and the
          occasional update about ReferralCandy.
        </Modal>
      )
  }
}

export default (props) => {
  return (
    <Location>
      {({ location }) => (
        <EmailCaptureModal currentUrl={location.href} {...props} />
      )}
    </Location>
  )
}
