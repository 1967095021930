import classnames from "classnames"
import React, { Component } from "react"
import { Modal } from "react-overlays"
import css from "./index.module.scss"

export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"

export const update = (prev = false, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return true
    case "CLOSE_MODAL":
      return false
    default:
      return prev
  }
}

export default class BaseModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scroll_position_y: 0,
    }
  }

  _disableScroll(modalOpen) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (modalOpen) {
      // save scroll position so we can scroll to it once modal is closed
      this.setState({
        scroll_position_y: window.pageYOffset,
      })

      // body overflow hidden style is set by react-overlay already
      window.document.documentElement.classList.add(css.fixed)

      // Using opacity to create a z-index stacking context local to gatsby content
      // So that modal stacking context won't compete with gatsby content
      // This hack gave me great joy
      window.document.getElementById("___gatsby").style.opacity = "0.9"
    } else {
      window.document.documentElement.classList.remove(css.fixed)
      window.scrollTo(0, this.state.scroll_position_y)
      window.document.getElementById("___gatsby").style.opacity = null
      window.document.getElementById("___gatsby").style.marginTop = null
    }
  }

  _showModal = () => {
    this._disableScroll(true)
  }

  _hideModal = () => {
    this._disableScroll(false)

    this.props.onUpdate({
      type: CLOSE_MODAL,
    })
  }

  componentWillUnmount() {
    this._disableScroll(false)
  }

  _onCloseClick = (e) => {
    e.preventDefault()
    this._hideModal()
  }

  _renderBackdrop() {
    return <div className={classnames(css.fade_in, css.backdrop)} />
  }

  render() {
    const {
      children,
      className,
      show,
      header,
      size = "medium",
      ...rest
    } = this.props
    delete rest.onUpdate

    const className_ = classnames(css.fixed, css.modal_container, className)

    return (
      <Modal
        {...rest}
        role="dialog"
        className={className_}
        show={show}
        autoFocus
        onHide={this._hideModal}
        onShow={this._showModal}
        backdrop
        keyboard
        restoreFocus
        renderBackdrop={this._renderBackdrop}
      >
        <div className={classnames(css.modal, css.fade_move_in, css[size])}>
          <div className={css.heading}>
            {header}
            <span className={css.close} onClick={this._onCloseClick}>
              &#10005;
            </span>
          </div>
          <div className={css.content}>{children}</div>
        </div>
      </Modal>
    )
  }
}

/*
  Usage:
  const onUpdate = (action) => {
    this.setState({
      modalLocalState: Modal.update(this.state.modalLocalState, action)
    })
  }
  <Button {...Modal.bindToTrigger(onUpdate)}>
    Click me to open
  </Button>
  <Modal show={this.state.modalLocalState} onUpdate={onUpdate}>
    Modal Content
  </Modal>
 */
export const bindToTrigger = (onUpdate, open = true) => ({
  onClick: (e) => {
    e.preventDefault()

    onUpdate(
      open
        ? {
            type: OPEN_MODAL,
          }
        : {
            type: CLOSE_MODAL,
          }
    )
  },
})
