import React from "react"
import css from "./index.module.scss"
import fontType from "../../../../helpers/fontType.module.scss"
import Grid from "../../../Grid"

import VIDEO_RESOURCE_1 from "../../../../assets/images/video-resource-3.png"
import VIDEO_RESOURCE_2 from "../../../../assets/images/video-resource-1.png"
import VIDEO_RESOURCE_3 from "../../../../assets/images/video-resource-2.png"

export default () => {
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h2 style={{ marginBottom: "3rem" }}>Resources from ReferralCandy</h2>
      <div className={css.gallery}>
        <div className={css.frame}>
          <div className={css.art}>
            <a href="https://www.youtube.com/watch?v=DLDpOQAuJcI&list=PLPjwXEodB9SnBibxy_i25mp4DtQWXKwol&index=2">
              <img
                style={{ marginBottom: 0 }}
                src={VIDEO_RESOURCE_1}
                alt="How to choose the right rewards video"
                height="300"
                width="300"
              />
            </a>
          </div>
          <p className={fontType.b1}>How to choose the right rewards</p>
        </div>
        <div className={css.frame}>
          <div className={css.art}>
            <a href="https://www.youtube.com/watch?v=_64mbY35FYk&feature=youtu.be">
              <img
                style={{ marginBottom: 0 }}
                src={VIDEO_RESOURCE_2}
                alt="How to integrate with ReCharge video"
                height="300"
                width="300"
              />
            </a>
          </div>
          <p className={fontType.b1}>How to integrate with ReCharge</p>
        </div>
        <div className={css.frame}>
          <div className={css.art}>
            <a href="https://www.youtube.com/watch?v=pGUFWjQRNvY&list=PLPjwXEodB9SnBibxy_i25mp4DtQWXKwol">
              <img
                style={{ marginBottom: 0 }}
                src={VIDEO_RESOURCE_3}
                alt="How to test the referral process video"
                height="300"
                width="300"
              />
            </a>
          </div>
          <p className={fontType.b1}>How to test the referral process</p>
        </div>
      </div>
    </Grid>
  )
}
