import React, { useState } from "react"
import css from "./index.module.scss"
import fontType from "../../../../helpers/fontType.module.scss"
import Grid from "../../../Grid"
import Button from "../../../Button"
import * as modal from "../../../Modal"
import EmailCaptureModal, { SEGMENT_KEYS } from "../EmailCaptureModal"

import GROWTH_HANDBOOK_1 from "../../../../assets/images/growth-handbook-1.svg"
import GROWTH_HANDBOOK_2 from "../../../../assets/images/growth-handbook-2.svg"
import GROWTH_HANDBOOK_3 from "../../../../assets/images/growth-handbook-3.svg"

export default () => {
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)

  // where stateSetterFn is setModal1,2,3,n
  const toggleModal = (showModal, stateSetterFn) => {
    return (action) => {
      stateSetterFn(modal.update(showModal, action))
    }
  }

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h2 style={{ marginBottom: "3rem" }}>The Growth Handbooks</h2>
      <div className={css.gallery}>
        <div className={css.frame}>
          <div
            className={css.art}
            {...modal.bindToTrigger(toggleModal(modal1, setModal1))}
          >
            <img
              src={GROWTH_HANDBOOK_1}
              alt="ReferralCandy ecommerce Growth Hacking Handbook Chapter 1 Research"
              height="300"
              width="300"
            />
          </div>
          <p className={fontType.b1}>Chapter 1: Research</p>
          <p>The art and science of understanding your customers</p>
          <Button
            el="button"
            {...modal.bindToTrigger(toggleModal(modal1, setModal1))}
          >
            Get this ebook
          </Button>
        </div>
        <div className={css.frame}>
          <div
            className={css.art}
            {...modal.bindToTrigger(toggleModal(modal2, setModal2))}
          >
            <img
              src={GROWTH_HANDBOOK_2}
              alt="ReferralCandy ecommerce Growth Hacking Handbook Chapter 2 Blogging"
              height="300"
              width="300"
            />
          </div>
          <p className={fontType.b1}>Chapter 2: Blogging</p>
          <p>
            Blogging is a complete waste of time unless you do these 5 things
          </p>
          <Button
            el="button"
            {...modal.bindToTrigger(toggleModal(modal2, setModal2))}
          >
            Get this ebook
          </Button>
        </div>
        <div className={css.frame}>
          <div
            className={css.art}
            {...modal.bindToTrigger(toggleModal(modal2, setModal2))}
          >
            <img
              src={GROWTH_HANDBOOK_3}
              alt="ReferralCandy ecommerce Growth Hacking Handbook Chapter 3 Conversion"
              height="300"
              width="300"
            />
          </div>
          <p className={fontType.b1}>Chapter 3: Conversion</p>
          <p>Easy wins that have disproportionate effect on sales</p>
          <Button
            el="button"
            {...modal.bindToTrigger(toggleModal(modal3, setModal3))}
          >
            Get this ebook
          </Button>
        </div>
      </div>
      <EmailCaptureModal
        show={modal1}
        onUpdate={toggleModal(modal1, setModal1)}
        segmentDataKey={SEGMENT_KEYS.GROWTH_HANDBOOK_1}
      />
      <EmailCaptureModal
        show={modal2}
        onUpdate={toggleModal(modal2, setModal2)}
        segmentDataKey={SEGMENT_KEYS.GROWTH_HANDBOOK_2}
      />
      <EmailCaptureModal
        show={modal3}
        onUpdate={toggleModal(modal3, setModal3)}
        segmentDataKey={SEGMENT_KEYS.GROWTH_HANDBOOK_3}
      />
    </Grid>
  )
}
