import React, { useState } from "react"
import classnames from "classnames"

import css from "./index.module.scss"
import fontType from "../../../../helpers/fontType.module.scss"
import Grid from "../../../Grid"
import * as modal from "../../../Modal"
import EmailCaptureModal, { SEGMENT_KEYS } from "../EmailCaptureModal"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query {
    banner: file(relativePath: { eq: "images/introbanner.png" }) {
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
export default () => {
  const { banner } = useStaticQuery(query)

  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)

  // where stateSetterFn is setModal1,2,3,n
  const toggleModal = (showModal, stateSetterFn) => {
    return (action) => {
      stateSetterFn(modal.update(showModal, action))
    }
  }

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.image}>
        <Img
          fixed={banner.childImageSharp.fixed}
          alt="ReferralCandy's Ultimate Guide to Referral Marketing"
        />
      </div>

      <div className={css.text}>
        <h2>Referral Marketing Email courses</h2>

        <div className={css.textitem}>
          <p className={classnames(css.subheader, fontType.b1)}>
            Referral program quickstart
          </p>
          <p>A 4-day course on launching an effective referral program</p>
          <a {...modal.bindToTrigger(toggleModal(modal1, setModal1))}>
            Get this ebook
          </a>
        </div>

        <div className={css.textitem}>
          <p className={classnames(css.subheader, fontType.b1)}>
            Doubling your word of mouth
          </p>
          <p>The secret to getting even more people talking about your brand</p>
          <a {...modal.bindToTrigger(toggleModal(modal2, setModal2))}>
            Get this ebook
          </a>
        </div>
      </div>

      <EmailCaptureModal
        show={modal1}
        onUpdate={toggleModal(modal1, setModal1)}
        segmentDataKey={SEGMENT_KEYS.REFERRAL_PROGRAM_QUICKSTART}
      />
      <EmailCaptureModal
        show={modal2}
        onUpdate={toggleModal(modal2, setModal2)}
        segmentDataKey={SEGMENT_KEYS.WOM_EMAIL_COURSE}
      />
    </Grid>
  )
}
