import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "../../../Grid"
import Button from "../../../Button"

import Img from "gatsby-image"

import GradientBackground from "../../GradientBackground"
import css from "./index.module.scss"

// const GENERAL_WEBINAR_REGISTRATION_URL =
//   "https://try.referralcandy.com/webinars/"
const DEMO_WEBINAR_REGISTRATION_URL =
  "https://event.webinarjam.com/register/1/44gpntx"
const NEW_WEBINAR_REGISTRATION_URL =
  "https://event.webinarjam.com/register/10/940rxt8"
const ECOMMERCE_SHOW_URL = "https://event.webinarjam.com/register/9/34kmzty"
const UNCONVENTIONAL_Q4_URL = "https://event.webinarjam.com/register/13/8496xt5"

const query = graphql`
  query {
    spanishjesus: file(
      relativePath: { eq: "images/referralcandy-partner-manager.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default () => {
  const { spanishjesus } = useStaticQuery(query)
  return (
    <GradientBackground>
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.text}>
          <h2>Join our next webinar</h2>
          <p>
            ReferralCandy regularly holds webinars to walk you through referral
            program tips and other ways to grow your store.
          </p>
          <p>Ask Raul Galera, our partner manager, a question in real-time!</p>
        </div>
        <div className={css.image}>
          <a
            href={DEMO_WEBINAR_REGISTRATION_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              fluid={spanishjesus.childImageSharp.fluid}
              alt="ReferralCandy Partner Manager"
            />
          </a>
        </div>
      </Grid>
      <Grid centeredClassName={css.centered}>
        <div className={css.text}>
          <h3>Demo: How ReferralCandy Works</h3>
          <p>Learn how to use ReferralCandy on your online store</p>
        </div>
        <Button
          target="_blank"
          rel="noopener noreferrer"
          href={DEMO_WEBINAR_REGISTRATION_URL}
        >
          Book Your Seat
        </Button>
      </Grid>
      <Grid centeredClassName={css.centered}>
        <div className={css.text}>
          <h3>Turning Your Customer Base Into Your Marketing Team</h3>
          <p>Hosted by Ecommerce Tech’s Derric Haynie</p>
        </div>
        <Button
          target="_blank"
          rel="noopener noreferrer"
          href={NEW_WEBINAR_REGISTRATION_URL}
        >
          Book Your Seat
        </Button>
      </Grid>
      <Grid centeredClassName={css.centered}>
        <div className={css.text}>
          <h3>ReferralCandy at The Ecommerce Growth Show</h3>
          <p>Hosted by OmniConvert’s CEO Valentin Radu</p>
        </div>
        <Button
          target="_blank"
          rel="noopener noreferrer"
          href={ECOMMERCE_SHOW_URL}
        >
          Book Your Seat
        </Button>
      </Grid>
      <Grid centeredClassName={css.centered}>
        <div className={css.text}>
          <h3>How To Focus Your Marketing Strategy For An Unconventional Q4</h3>
          <p>Hosted by ReferralCandy and Klickly</p>
        </div>
        <Button
          target="_blank"
          rel="noopener noreferrer"
          href={UNCONVENTIONAL_Q4_URL}
        >
          Book Your Seat
        </Button>
      </Grid>
      <br />
      <br />
    </GradientBackground>
  )
}
